
import { defineComponent } from 'vue';
import Table from '@/components/Table/Table.vue';
import { TableHeader } from '@/components/Table/model/TableHeader';

export default defineComponent({
  name: 'Classes',
  components: {
    Table,
  },
  setup() {
    const scheduleHeaders: TableHeader[] = [
      {
        id: 'time',
        itemKey: 'time',
        title: '',
        show: true,
        align: 'right',
        compact: true,
      }, {
        id: 'monday',
        itemKey: 'monday',
        title: 'Lunes',
        show: true,
        align: 'center',
      }, {
        id: 'tuesday',
        itemKey: 'tuesday',
        title: 'Martes',
        show: true,
        align: 'center',
      }, {
        id: 'wednesday',
        itemKey: 'wednesday',
        title: 'Miercoles',
        show: true,
        align: 'center',
      }, {
        id: 'thursday',
        itemKey: 'thursday',
        title: 'Jueves',
        show: true,
        align: 'center',
      }, {
        id: 'friday',
        itemKey: 'friday',
        title: 'Viernes',
        show: true,
        align: 'center',
      },
    ];
    const schedule = [
      {
        time: '10 a 12h',
        tuesday: 'Modelado',
        // friday: 'Grupos / torno',
      }, {
        time: '12 a 14h',
        tuesday: 'Modelado',
      }, {
        time: '',
      }, {
        time: '17 a 19h',
        // monday: '',
        tuesday: 'Modelado',
        wednesday: 'Modelado',
        thursday: 'Modelado',
        friday: 'Grupos',
      }, {
        time: '18:30 a 21h',
        monday: 'Torno',
      }, {
        time: '19 a 21h',
        monday: '',
        //monday: 'Modelado',
        tuesday: 'Modelado',
        wednesday: 'Modelado',
        thursday: 'Modelado',
      },
    ];

    return {
      scheduleHeaders,
      schedule,
    };
  },
});
